import {
  useState, useEffect, useRef,
  forwardRef, useImperativeHandle, useCallback,
} from 'react';
import { createFragmentContainer, graphql, useFragment } from 'react-relay';
import { nanoid } from 'nanoid';
import miniQueryLoader from '@/helpers/miniQueryLoader';

const fragmentGoogleItem = graphql`
  fragment googleLists_lists on Query @argumentDefinitions(
    pattern: {type: "String!"}
  ) {
    lists: getPlacesByPattern(pattern: $pattern) {
      gg_place_id: place_id
      slug
      title
    }
  }
`;
const queryGoogleLists = graphql`
  query googleListsQuery ($pattern: String!) {
    ...googleLists_lists @arguments(pattern: $pattern)
  }
`;

function GoogleLists({
 onClick, fwdRef, setPattern,
}) {
  const divRef = useRef();
  const [currentList, setCurrentList] = useState([]);

  useImperativeHandle(fwdRef, () => ({
    setCurrentList,
  }), [currentList]);

  const handleClickOutside = useCallback((event) => {
    if (divRef && divRef.current && !divRef.current.contains(event.target)) {
      setPattern('');
    }
  });
  useEffect(() => {
    if (currentList?.length) {
      window.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, [currentList]);

  if (!currentList?.length) return null;

  return (
  <div className="z-30 shadow-button rounded-lg absolute mt-2 right-0 left-0 bg-white grid gap-1 overflow-hidden text-left" ref={divRef}>
    {currentList.map((gr) => (
      <div className="cursor-pointer py-3 px-4 text-dark-deep transition hover:bg-blue-light hover:text-blue" key={gr.id || nanoid()} onClick={() => onClick(gr)}>{gr.title}</div>
    ))}
  </div>
  );
}

const MiniQueryLoaderGCLists = miniQueryLoader({
  Component: ({ data, setCurrentList }) => {
    const { lists } = useFragment(fragmentGoogleItem, data) || {};
    useEffect(() => {
      setCurrentList(lists);
    }, [data]);
    return null;
  },
  query: queryGoogleLists,
  providerVariables: ({ pattern }) => ({ pattern }),
});

function ListenerGC({ pattern: _pattern, fwdRef, setCurrentList }) {
  const [pattern, setPattern] = useState(_pattern);
  const [callQL, setCallQL] = useState(null);
  useImperativeHandle(fwdRef, () => ({
    setPattern: (value) => {
      if (value && typeof value === 'string') {
        setPattern(value);
        setCallQL(nanoid());
      } else if (value && ['function', 'object'].includes(typeof value) && value?.()) {
        setPattern(value?.());
        setCallQL(nanoid());
      } else {
        setCurrentList(null);
        setCallQL(null);
      }
    },
  }), [pattern]);

  return !callQL || <MiniQueryLoaderGCLists key={`fieldhubgooglelistener${pattern}`} pattern={pattern} setCurrentList={setCurrentList} />;
}
export default forwardRef(({ onClick }, ref) => {
  const refFragment = useRef();

  return <>
    <ListenerGC
      fwdRef={ref}
      setCurrentList={(lists) => {
      refFragment?.current?.setCurrentList(lists);
    }}
    />
    <GoogleLists fwdRef={refFragment} setPattern={(value) => ref?.current?.setPattern(() => value)} onClick={onClick} />
         </>;
});
